import type { MouseEvent } from "react";
import { toColor } from "react-color-palette";
import { classNames } from "@/react/helpers/twMergeWithCN";
import { usePickerContext } from "../../pickerContext";
import { NewCustomColorButton } from "../CustomColors/NewCustomColorButton";
import { isCssVariable } from "./utils";

interface SwatchesProps {
  isThemeColorsSwatches?: boolean;
}

interface ProcessedColor {
  key: string;
  displayValue: string;
}

export const Swatches = ({ isThemeColorsSwatches = false }: SwatchesProps) => {
  const { customColors, themeColors, initialColor, onColorChange } =
    usePickerContext();

  const colorSwatch = isThemeColorsSwatches ? themeColors : customColors;

  if (!colorSwatch) {
    return null;
  }

  const handleThemeColorSelect = (
    colorValue: string,
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    if (isCssVariable(colorValue)) {
      onColorChange?.(colorValue);
    }
  };

  const handleCustomColorSelect = (
    colorValue: string,
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    try {
      const convertedColor = toColor("hex", colorValue);
      onColorChange?.(convertedColor.hex);
    } catch (error) {
      console.warn(`Failed to convert color: ${colorValue}`);
      onColorChange?.(colorValue);
    }
  };

  const handleColorSelect = isThemeColorsSwatches
    ? handleThemeColorSelect
    : handleCustomColorSelect;

  const processColorsFromObject = (): ProcessedColor[] =>
    Object.entries(colorSwatch).map(([key, value]) => ({
      key,
      displayValue: isThemeColorsSwatches ? key : value,
    }));

  const processedColors = processColorsFromObject();

  const isColorSelected = (color: string): boolean => initialColor === color;

  return (
    <div className="flex flex-wrap gap-2">
      {processedColors.map(({ key, displayValue }) => {
        const isSelected = isColorSelected(displayValue);

        return (
          <button
            type="button"
            key={key}
            style={{ backgroundColor: displayValue }}
            className={classNames("border", {
              "border-darkest border-2": isSelected,
              "border-primary": !isSelected,
              "h-8 w-8 rounded-md": isThemeColorsSwatches,
              "h-6 w-6 rounded-full": !isThemeColorsSwatches,
            })}
            aria-label={displayValue}
            onClick={e => handleColorSelect(displayValue, e)}
          />
        );
      })}

      {!isThemeColorsSwatches && <NewCustomColorButton />}
    </div>
  );
};
