import PropTypes from "prop-types";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  SearchableSelect,
  filterByLabel,
  useSearchableSelect,
} from "@circle-react-uikit/SearchableSelect";
import { GroupedOptions } from "./GroupedOptions";
import { RegularOptions } from "./RegularOptions";

export const TextSelect = ({
  options = [],
  onChange = noop,
  onSearchChange = noop,
  isError = false,
  placeholder = t("select_an_option"),
  emptyMessage = t("no_options"),
  filterBy = filterByLabel,
  groupBy = null,
  displayValue = option => option?.label || "",
  initialValue = "",
  value,
  ...rest
}) => {
  const {
    selectProps,
    searchResults,
    hasNoResults,
    groupedSearchResults,
    hasGroups,
  } = useSearchableSelect({
    isError,
    options,
    onChange,
    filterBy,
    placeholder,
    displayValue,
    onSearchChange,
    groupByValue: groupBy,
    initialValue,
    value,
  });

  return (
    <SearchableSelect {...rest} {...selectProps}>
      {hasNoResults && <SearchableSelect.EmptyOption label={emptyMessage} />}
      {!hasNoResults && !hasGroups && (
        <RegularOptions options={searchResults} />
      )}
      {!hasNoResults && hasGroups && (
        <GroupedOptions groupedOptions={groupedSearchResults} />
      )}
    </SearchableSelect>
  );
};

TextSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  placeholder: PropTypes.string,
  emptyMessage: PropTypes.string,
  filterBy: PropTypes.func,
  displayValue: PropTypes.func,
  groupBy: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  isError: PropTypes.bool,
};
