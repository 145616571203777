import { t } from "@/i18n-js/instance";
import { Typography } from "../../../uikit/Typography";
import { usePickerContext } from "../../pickerContext";
import { Swatches } from "../ThemeColors/Swatches";

export const CustomColors = () => {
  const { customColors } = usePickerContext();

  if (!customColors) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-start justify-start gap-3 px-4 pt-4">
      <Typography.LabelXs color="text-default" weight="medium">
        {t("color_picker_v2.theme_picker.my_colors")}
      </Typography.LabelXs>
      <Swatches />
    </div>
  );
};
