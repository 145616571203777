import * as PropTypes from "prop-types";

/** @deprecated Please use inline boolean conditionals to render JSX */
export const If = ({ condition, children }) => {
  const renderChildren =
    typeof children === "function" ? children : () => children;
  return condition ? renderChildren() : null;
};

If.propTypes = {
  condition: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};
