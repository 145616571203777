import { COLOR_PICKER_V2_VIEWS } from "../..";
import { Icon } from "../../../Icon";
import { usePickerContext } from "../../pickerContext";

export const NewCustomColorButton = () => {
  const { setCurrentView } = usePickerContext();
  return (
    <button
      type="button"
      className="bg-secondary border-primary flex h-6 w-6 items-center justify-center rounded-full border border-dashed"
      onClick={() => setCurrentView(COLOR_PICKER_V2_VIEWS.PICKER)}
    >
      <Icon type="plus" />
    </button>
  );
};
