import { createContext, useContext } from "react";
import type { MutableRefObject } from "react";
import { noop } from "lodash";

interface PopoverContextProps {
  onClose: (
    focusableElement?:
      | HTMLElement
      | MutableRefObject<HTMLElement | null>
      | undefined,
  ) => void;
}

export const PopoverPortalContext = createContext<PopoverContextProps>({
  onClose: noop,
});

PopoverPortalContext.displayName = "PopoverPortalContext";

export const usePopoverPortalContext = () => useContext(PopoverPortalContext);
