import { SearchableSelect } from "@circle-react-uikit/SearchableSelect";
import { Typography } from "@circle-react-uikit/Typography";
import type { Option } from "./GroupedOptions";

export interface RegularOptionsProps {
  options?: Option[];
}

export const RegularOptions = ({ options = [] }: RegularOptionsProps) => (
  <>
    {options.map(option => {
      const { label, value, description, leftAddon } = option;

      return (
        <SearchableSelect.Option key={value} option={option}>
          <div className="text-default flex flex-row items-center gap-2">
            {leftAddon}
            <div className="flex flex-1 flex-col gap-1">
              <Typography.LabelSm weight="medium">{label}</Typography.LabelSm>
              {description && (
                <Typography.LabelXs>
                  <span className="text-light">{description}</span>
                </Typography.LabelXs>
              )}
            </div>
          </div>
        </SearchableSelect.Option>
      );
    })}
  </>
);
