import { HSVInput } from "./ModelInputs/HSVInput";
import { HexInput } from "./ModelInputs/HexInput";
import { RGBInput } from "./ModelInputs/RGBInput";

export const COLOR_MODE_COMPONENT_MAP = {
  hex: HexInput,
  rgb: RGBInput,
  hsv: HSVInput,
} as const;

export const POPPER_OPTIONS = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [8, 8],
      },
    },
  ],
};
