import { useCallback, useRef } from "react";
import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames";
import { useClickAway } from "react-use";

export { useDropdown } from "./useDropdown";

export interface DropdownMenuProps extends ComponentPropsWithoutRef<"div"> {
  handleClose?: (event: any) => void;
  open?: boolean;
  disableToggle?: boolean;
}

/** @deprecated Please use the new Dropdown at "@circle-react-uikit/Dropdown" */
export const DropdownMenu_DEPRECATED = ({
  className,
  children,
  handleClose,
  open,
  disableToggle,
  ...rest
}: DropdownMenuProps) => {
  const ref = useRef(null);

  const handleClickAway = useCallback(
    event => {
      if (open && !disableToggle && handleClose) {
        handleClose(event);
      }
    },
    [open, disableToggle, handleClose],
  );

  useClickAway(ref, handleClickAway);

  return (
    <div
      ref={ref}
      className={classnames(
        "dropdown-menu--bottom-bar !border-primary border",
        className,
        {
          hidden: !open,
        },
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

DropdownMenu_DEPRECATED.displayName = "DropdownMenu";
