import { round } from "lodash";
import type { Color } from "react-color-palette";
import type { HSVColor } from "./ModelInputs/HSVInput";
import type { RGBColor } from "./ModelInputs/RGBInput";

export const getRGBFromColor = (color: Color): RGBColor => ({
  r: round(color.rgb.r),
  g: round(color.rgb.g),
  b: round(color.rgb.b),
  a: round(color.rgb.a ?? 1, 1),
});

export const getHSVFromColor = (color: Color): HSVColor => ({
  h: round(color.hsv.h),
  s: round(color.hsv.s),
  v: round(color.hsv.v),
  a: round(color.hsv.a ?? 1, 1),
});
