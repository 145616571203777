import { t } from "@/i18n-js/instance";
import { Icon } from "../../Icon";
import { Dropdown } from "../../uikit/Dropdown";
import { Typography } from "../../uikit/Typography";
import { POPPER_OPTIONS } from "./constants";

export type ColorModel = "hex" | "rgb" | "hsv";

interface ColorModelSelectorProps {
  model: ColorModel;
  onChange: (value: ColorModel) => void;
}

export const ColorModelSelector = ({
  model,
  onChange,
}: ColorModelSelectorProps) => (
  <Dropdown
    size="small"
    className="border-primary bg-primary text-dark group-hover:border-darkest focus:border-darkest group-focus-within:border-darkest w-[7ch] appearance-none rounded-lg border px-2 py-1 text-center uppercase focus:!outline-none focus:!ring-0"
    menuItemsClassName="!p-2"
    popperOptions={POPPER_OPTIONS}
    button={
      <Dropdown.MenuButton className="flex w-full items-center justify-between !py-0">
        <Typography.LabelSm textTransform="uppercase">
          {model}
        </Typography.LabelSm>
        <Icon type="12-chevron-down" size={12} />
      </Dropdown.MenuButton>
    }
  >
    <Dropdown.ItemWithLink onClick={() => onChange("hex")}>
      <Typography.LabelSm textTransform="uppercase">
        {t("color_picker_v2.hex")}
      </Typography.LabelSm>
    </Dropdown.ItemWithLink>
    <Dropdown.ItemWithLink onClick={() => onChange("rgb")}>
      <Typography.LabelSm textTransform="uppercase">
        {t("color_picker_v2.rgb")}
      </Typography.LabelSm>
    </Dropdown.ItemWithLink>
    <Dropdown.ItemWithLink onClick={() => onChange("hsv")}>
      <Typography.LabelSm textTransform="uppercase">
        {t("color_picker_v2.hsv")}
      </Typography.LabelSm>
    </Dropdown.ItemWithLink>
  </Dropdown>
);
