export const CSS_VAR_REGEX = /^var\(--([^)]+)\)$/;

interface ResolveCssVariableProps {
  value: string;
  cssVariables: Record<string, string>;
}

export const resolveCssVariable = ({
  value,
  cssVariables,
}: ResolveCssVariableProps): string => {
  if (!value) {
    return value;
  }

  const match = CSS_VAR_REGEX.exec(value);

  if (!match) {
    return value;
  }

  const [, variableName] = match;
  const resolvedValue = cssVariables[variableName];

  if (!resolvedValue) {
    console.warn(`CSS variable "${variableName}" not found in cssVariables`);
    return value;
  }

  return CSS_VAR_REGEX.test(resolvedValue)
    ? resolveCssVariable({ value: resolvedValue, cssVariables })
    : resolvedValue;
};
