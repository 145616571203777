import { t } from "@/i18n-js/instance";
import { Typography } from "../../../uikit/Typography";
import { usePickerContext } from "../../pickerContext";
import { Swatches } from "./Swatches";

export const ThemeColors = () => {
  const { themeColors } = usePickerContext();

  if (!themeColors) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-start justify-start gap-3 p-4">
      <Typography.LabelXs color="text-default" weight="medium">
        {t("color_picker_v2.theme_picker.theme_colors")}
      </Typography.LabelXs>
      <Swatches isThemeColorsSwatches />
    </div>
  );
};
