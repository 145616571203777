export const UploadIcon = () => (
  <svg
    width="44"
    height="38"
    viewBox="0 0 44 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="upload-box__icon text-dark"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3777 6.80158C28.6935 4.74465 25.9889 2.97327 21.6681 3.10706C17.4236 3.23848 14.7744 5.48997 13.2292 8.10562C12.4494 9.42552 11.958 10.8317 11.7051 12.0818C11.4476 13.3554 11.4558 14.3744 11.5879 14.9366C11.6675 15.2752 11.5822 15.6315 11.358 15.8974C11.1338 16.1633 10.797 16.3074 10.4498 16.2862C8.4286 16.1623 6.40499 17.0592 4.98172 18.5551C3.56367 20.0455 2.81802 22.0458 3.20105 24.0771C4.02348 28.4386 7.14002 30.0617 9.19975 30.0617H16.6964L16.6966 31.1617L16.6966 32.2617H9.19975C6.06927 32.2617 2.05518 29.873 1.03914 24.4847C0.502933 21.6411 1.57033 18.9489 3.38787 17.0386C4.9152 15.4333 7.02647 14.3201 9.29852 14.1105C9.28722 13.3616 9.37247 12.5176 9.5488 11.6457C9.84529 10.1796 10.4182 8.53859 11.335 6.98661C13.183 3.85847 16.4561 1.06739 21.6 0.908113C26.6676 0.751202 30.0047 2.87347 32.0799 5.40782C33.77 7.47204 34.6139 9.79286 34.9361 11.3414C36.1505 11.6153 37.9482 12.2762 39.5995 13.4957C41.7581 15.0899 43.7204 17.6866 43.7412 21.6234C43.8182 25.7143 42.0708 28.4439 39.5886 30.1008C37.1705 31.7149 34.1397 32.2617 31.6184 32.2617H27.8851C27.2776 32.2617 26.785 31.7692 26.785 31.1617C26.785 30.5541 27.2775 30.0617 27.885 30.0617H31.6184C33.8811 30.0617 36.4338 29.5616 38.3672 28.271C40.2348 27.0244 41.6062 24.994 41.5415 21.658L41.5413 21.6417C41.5271 18.5421 40.0239 16.544 38.2925 15.2654C36.5143 13.9522 34.5511 13.4476 33.8832 13.3908C33.357 13.346 32.9368 12.9335 32.8824 12.4081C32.7719 11.3427 32.1033 8.90909 30.3777 6.80158ZM17.7966 31.1617C17.7966 31.7692 17.3041 32.2617 16.6966 32.2617L16.6966 31.1617L16.6964 30.0617C17.3039 30.0617 17.7966 30.5541 17.7966 31.1617Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3207 17.9012C22.9282 17.9012 23.4207 18.3937 23.4207 19.0012V35.9774C23.4207 36.5849 22.9282 37.0774 22.3207 37.0774C21.7132 37.0774 21.2207 36.5849 21.2207 35.9774V19.0012C21.2207 18.3937 21.7132 17.9012 22.3207 17.9012Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5463 18.1714C21.9763 17.7453 22.6698 17.7467 23.098 18.1746L28.3224 23.3943C28.7522 23.8237 28.7525 24.5202 28.3231 24.9499C27.8937 25.3797 27.1972 25.38 26.7674 24.9506L22.3174 20.5045L17.8274 24.9538C17.3959 25.3814 16.6994 25.3783 16.2718 24.9467C15.8442 24.5152 15.8473 23.8187 16.2789 23.3911L21.5463 18.1714Z"
      fill="currentColor"
    />
  </svg>
);
