import type { ReactNode } from "react";
import { RegularOptions } from "./RegularOptions";

export interface Option {
  label: string;
  value: string;
  description: string;
  leftAddon: ReactNode;
}

export interface GroupedOptionsProps {
  groupedOptions: Record<string, Option[]>;
}

export const GroupedOptions = ({
  groupedOptions = {},
}: GroupedOptionsProps) => (
  <div className="flex flex-col gap-5">
    {Object.entries(groupedOptions).map(([group, options]) => (
      <div key={group}>
        <h6 className="text-light block select-none px-5 py-2 text-xs font-semibold uppercase">
          {group}
        </h6>
        <RegularOptions options={options} />
      </div>
    ))}
  </div>
);
