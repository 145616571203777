import PropTypes from "prop-types";

export const EmojiRendererWrapper = ({
  isWrapperElementClickable,
  wrapperClassName,
  children,
}) => {
  if (isWrapperElementClickable) {
    return (
      <button type="button" className={wrapperClassName}>
        {children}
      </button>
    );
  }
  if (wrapperClassName) {
    return <div className={wrapperClassName}>{children}</div>;
  }

  return children;
};

EmojiRendererWrapper.propTypes = {
  isWrapperElementClickable: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};
