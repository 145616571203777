import { useState } from "react";
import { isEmpty } from "lodash";
import type { PopperProps } from "react-popper";
import { Header } from "./Header";
import { ColorPicker } from "./Picker";
import { PopperWrapper } from "./PopperWrapper";
import { ThemePicker } from "./ThemePicker";
import { PickerProvider } from "./pickerContext";

interface ColorPickerV2Props {
  shouldRenderThemeColors?: boolean;
  initialColor?: string;
  onColorChange?: (color: string) => void;
  popperOptions?: PopperProps<any>["modifiers"];
  themeColors?: Record<string, string>;
  customColors?: Record<string, string>;
  onAddCustomColor?: (color: string) => void;
  cssVariables?: Record<string, string>;
}

export const COLOR_PICKER_V2_VIEWS = {
  PICKER: "picker",
  THEME_COLORS: "themeColors",
} as const;

export type ColorPickerV2View =
  (typeof COLOR_PICKER_V2_VIEWS)[keyof typeof COLOR_PICKER_V2_VIEWS];

export const ColorPickerV2 = ({
  initialColor,
  onColorChange,
  popperOptions,
  shouldRenderThemeColors = false,
  themeColors,
  customColors,
  cssVariables,
  onAddCustomColor,
}: ColorPickerV2Props) => {
  const initialView = shouldRenderThemeColors
    ? COLOR_PICKER_V2_VIEWS.THEME_COLORS
    : COLOR_PICKER_V2_VIEWS.PICKER;
  const [currentView, setCurrentView] =
    useState<ColorPickerV2View>(initialView);

  const shouldShowThemeColors =
    shouldRenderThemeColors &&
    currentView === COLOR_PICKER_V2_VIEWS.THEME_COLORS &&
    !isEmpty(themeColors);

  const shouldShowPicker = currentView === COLOR_PICKER_V2_VIEWS.PICKER;

  const providerProps = {
    initialColor,
    onColorChange,
    popperOptions,
    themeColors,
    customColors,
    currentView,
    setCurrentView,
    shouldRenderThemeColors,
    onAddCustomColor,
    cssVariables,
  };

  return (
    <PickerProvider {...providerProps}>
      <PopperWrapper>
        <Header />
        {shouldShowThemeColors && <ThemePicker />}
        {shouldShowPicker && <ColorPicker />}
      </PopperWrapper>
    </PickerProvider>
  );
};
