import type { ReactNode } from "react";
import classNames from "classnames";

export interface ListItemProps {
  onSelect: (item: any, event: any) => void;
  item: any;
  selected: boolean;
  content: ReactNode;
}

export const ListItem = ({
  onSelect,
  item,
  selected,
  content,
  ...rest
}: ListItemProps) => {
  const onClickHandler = (event: any) => {
    onSelect(item, event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- We can't use <button> here because the prop content is a ReactNode
    <li
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role -- We can't use <button> here because the prop content is a ReactNode
      role="button"
      className={classNames("block-selector-item", {
        active: selected,
        inactive: !selected,
      })}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      tabIndex={0}
      {...rest}
    >
      {content}
    </li>
  );
};
