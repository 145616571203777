import { useCallback, useState } from "react";

export const useDropdown = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggleDropdown = useCallback(
    event => {
      event && event.preventDefault();
      setIsOpen(state => !state);
    },
    [setIsOpen],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return {
    open: isOpen,
    toggleDropdown,
    handleOpen,
    handleClose,
    setOpen: setIsOpen,
  };
};
