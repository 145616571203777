import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { Popover } from "@headlessui/react";
import { toColor, useColor } from "react-color-palette";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import type { PopperProps } from "react-popper";
import { HEADLESS_UI_POPOVER_ROOT } from "../uikit/PopoverPortal";
import { PopoverPortalContext } from "./PopoverPortalContext";
import { usePickerContext } from "./pickerContext";

export interface PopperWrapperProps {
  children: ReactNode;
  popperOptions?: PopperProps<any>["modifiers"];
}

export const PopperWrapper = ({ children }: PopperWrapperProps) => {
  const { initialColor = "#000000", popperOptions = [] } = usePickerContext();
  const [color, setColor] = useColor(
    "hex",
    initialColor.startsWith("var(") ? "#000000" : initialColor,
  );
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const isInitialColorCssVariable = initialColor.startsWith("var(");

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      ...popperOptions,
    ],
  });

  useEffect(() => {
    if (!isInitialColorCssVariable) {
      setColor(toColor("hex", initialColor));
    }
  }, [initialColor, setColor, isInitialColorCssVariable]);

  const buttonBackgroundColor = isInitialColorCssVariable
    ? initialColor
    : color.hex;

  return (
    <Popover as="div" className="relative flex" data-testid="color-picker-v2">
      {({ open, close }) => {
        const contextProps = { open, onClose: close };
        const portalRoot = document.getElementById(HEADLESS_UI_POPOVER_ROOT);

        return (
          <PopoverPortalContext.Provider value={contextProps}>
            <Popover.Button
              ref={setReferenceElement}
              className="border-primary h-7 w-7 rounded-lg border"
              style={{ backgroundColor: buttonBackgroundColor }}
            />

            {createPortal(
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="bg-primary border-primary z-[9999] flex w-[20rem] flex-col items-center justify-center rounded-lg border pb-4 shadow-2xl"
              >
                {children}
              </Popover.Panel>,
              portalRoot ?? document.body,
            )}
          </PopoverPortalContext.Provider>
        );
      }}
    </Popover>
  );
};
