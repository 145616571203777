import { memo, useCallback, useEffect, useRef, useState } from "react";
import { colord } from "colord";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { Color } from "react-color-palette";
import { toColor } from "react-color-palette";
import { getHSVFromColor } from "../utils";
import { ColorInput } from "./ColorInput";

export interface HSVColor {
  h: number;
  s: number;
  v: number;
  a: number;
}

interface HSVInputProps {
  color: Color;
  setColor: (color: Color) => void;
}

type HSVKey = "h" | "s" | "v" | "a";

export const HSVInput = memo(({ color, setColor }: HSVInputProps) => {
  const [hsv, setHSV] = useState<HSVColor>(getHSVFromColor(color));
  const debouncedFnRef = useRef<DebouncedFunc<
    (newHSV: HSVColor) => void
  > | null>(null);

  const updateColor = useCallback(
    (newHSV: HSVColor) => {
      const validatedHSV = colord({
        h: newHSV.h,
        s: newHSV.s,
        v: newHSV.v,
        a: newHSV.a,
      }).toHsv();
      setColor(toColor("hsv", validatedHSV));
    },
    [setColor],
  );

  useEffect(() => {
    debouncedFnRef.current = debounce(updateColor, 500);

    return () => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current.cancel();
      }
    };
  }, [updateColor]);

  const handleChange = useCallback((field: HSVKey, value: number) => {
    setHSV(prevHSV => {
      const newHSV = { ...prevHSV, [field]: value };
      if (debouncedFnRef.current) {
        debouncedFnRef.current(newHSV);
      }
      return newHSV;
    });
  }, []);

  useEffect(() => {
    setHSV(getHSVFromColor(color));
  }, [color]);

  return (
    <div className="flex gap-2">
      <ColorInput
        key="h"
        value={hsv.h}
        onChange={val => handleChange("h", val)}
        min={0}
        max={360}
      />
      <ColorInput
        key="s"
        value={hsv.s}
        onChange={val => handleChange("s", val)}
        min={0}
        max={100}
      />
      <ColorInput
        key="v"
        value={hsv.v}
        onChange={val => handleChange("v", val)}
        min={0}
        max={100}
      />
      <ColorInput
        key="a"
        value={hsv.a}
        onChange={val => handleChange("a", val)}
        min={0}
        max={1}
        step={0.01}
      />
    </div>
  );
});

HSVInput.displayName = "HSVInput";
