import { memo, useCallback, useEffect, useRef, useState } from "react";
import { colord } from "colord";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import type { Color } from "react-color-palette";
import { toColor } from "react-color-palette";
import { getRGBFromColor } from "../utils";
import { ColorInput } from "./ColorInput";

export interface RGBColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

interface RGBInputProps {
  color: Color;
  setColor: (color: Color) => void;
}

type RGBKey = "r" | "g" | "b" | "a";

export const RGBInput = memo(({ color, setColor }: RGBInputProps) => {
  const [rgb, setRGB] = useState<RGBColor>(getRGBFromColor(color));
  const debouncedFnRef = useRef<DebouncedFunc<
    (newRGB: RGBColor) => void
  > | null>(null);

  const updateColor = useCallback(
    (newRGB: RGBColor) => {
      const validatedRGB = colord(newRGB).toRgb();
      setColor(toColor("rgb", validatedRGB));
    },
    [setColor],
  );

  useEffect(() => {
    debouncedFnRef.current = debounce(updateColor, 500);

    return () => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current.cancel();
      }
    };
  }, [updateColor]);

  const handleChange = useCallback((field: RGBKey, value: number) => {
    setRGB(prevRGB => {
      const newRGB = { ...prevRGB, [field]: value };
      if (debouncedFnRef.current) {
        debouncedFnRef.current(newRGB);
      }
      return newRGB;
    });
  }, []);

  useEffect(() => {
    setRGB(getRGBFromColor(color));
  }, [color]);

  return (
    <div className="flex w-full items-center gap-2">
      <ColorInput
        key="r"
        value={rgb.r}
        onChange={val => handleChange("r", val)}
        min={0}
        max={255}
      />
      <ColorInput
        key="g"
        value={rgb.g}
        onChange={val => handleChange("g", val)}
        min={0}
        max={255}
      />
      <ColorInput
        key="b"
        value={rgb.b}
        onChange={val => handleChange("b", val)}
        min={0}
        max={255}
      />
      <ColorInput
        key="a"
        value={rgb.a}
        onChange={val => handleChange("a", val)}
        min={0}
        max={1}
        step={0.01}
      />
    </div>
  );
});

RGBInput.displayName = "RGBInput";
