import { useEffect, useRef, useState } from "react";
import type { MouseEvent } from "react";
import type { Instance } from "tippy.js";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "../../TippyV2";
import { Typography } from "../../uikit/Typography";
import { usePickerContext } from "../pickerContext";
import { CustomColors } from "./CustomColors";
import { ThemeColors } from "./ThemeColors";
import { isCssVariable } from "./ThemeColors/utils";

export const ThemePicker = () => {
  const { initialColor, themeColors, cssVariables } = usePickerContext();
  const [shouldDisplayCopiedFeedback, setShouldDisplayCopiedFeedback] =
    useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tippyInstanceRef = useRef<Instance | null>(null);
  const timeoutRef = useRef<number | null>(null);

  const renderDisplayValue = () => {
    if (!initialColor) {
      return null;
    }

    if (isCssVariable(initialColor) && themeColors) {
      const colorValue = themeColors[initialColor];
      return colorValue || initialColor;
    }

    return initialColor;
  };

  const handleCopyClick = (event: MouseEvent) => {
    event.stopPropagation();

    const valueToCopy = renderDisplayValue();

    if (valueToCopy) {
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
          setShouldDisplayCopiedFeedback(true);
          setIsTooltipVisible(true);

          if (timeoutRef.current !== null) {
            window.clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = window.setTimeout(() => {
            setShouldDisplayCopiedFeedback(false);
            setIsTooltipVisible(false);
            timeoutRef.current = null;
          }, 2000);
        })
        .catch(err => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  useEffect(() => {
    setShouldDisplayCopiedFeedback(false);
    setIsTooltipVisible(false);
  }, [initialColor]);

  useEffect(
    () => () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const tooltipContent = shouldDisplayCopiedFeedback
    ? t("color_picker_v2.copied")
    : t("color_picker_v2.click_to_copy");

  const displayValue = renderDisplayValue();

  const cssVariableStyles = cssVariables
    ? Object.entries(cssVariables).reduce(
        (acc, [key, value]) => ({ ...acc, [`--${key}`]: value }),
        {},
      )
    : {};

  return (
    <div
      className="flex w-full flex-col items-start justify-start"
      style={cssVariableStyles}
    >
      <div className="divide-primary w-full divide-y">
        <ThemeColors />
        <CustomColors />
      </div>
      <div className="mb-0 mt-auto flex w-full justify-end px-4 pt-4">
        <TippyV2
          content={tooltipContent}
          shouldUseSpanWrapper={false}
          arrow={false}
          interactive={false}
          visible={isTooltipVisible}
          onMount={instance => {
            tippyInstanceRef.current = instance;
          }}
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: { offset: [0, 4] },
              },
            ],
          }}
        >
          <button
            type="button"
            onClick={handleCopyClick}
            className="p-1 focus:outline-none"
            onMouseEnter={() => {
              if (!shouldDisplayCopiedFeedback) {
                setIsTooltipVisible(true);
              }
            }}
            onMouseLeave={() => {
              if (!shouldDisplayCopiedFeedback) {
                setIsTooltipVisible(false);
              }
            }}
          >
            <Typography.LabelSm color="text-default" textTransform="uppercase">
              {displayValue}
            </Typography.LabelSm>
          </button>
        </TippyV2>
      </div>
    </div>
  );
};
