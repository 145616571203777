import { useCallback, useEffect, useRef, useState } from "react";
import { COLOR_PICKER_V2_VIEWS } from "..";
import {
  ColorPicker as ColorPickerComponent,
  toColor,
  useColor,
} from "react-color-palette";
import { t } from "@/i18n-js/instance";
import { Button } from "../../uikit/Button";
import { usePopoverPortalContext } from "../PopoverPortalContext";
import { isCssVariable } from "../ThemePicker/ThemeColors/utils";
import { usePickerContext } from "../pickerContext";
import { ColorModelSelector } from "./ColorMode";
import { COLOR_MODE_COMPONENT_MAP } from "./constants";
import "./styles.scss";

export type ColorModel = "hex" | "rgb" | "hsv";

export const ColorPicker = () => {
  const {
    initialColor = "#000000",
    onColorChange,
    onAddCustomColor,
    setCurrentView,
    themeColors,
    shouldRenderThemeColors,
  } = usePickerContext();

  const getEffectiveColor = useCallback(
    (colorValue: string) => {
      if (isCssVariable(colorValue) && themeColors) {
        return themeColors[colorValue] || "#000000";
      }
      return colorValue;
    },
    [themeColors],
  );

  const { onClose } = usePopoverPortalContext();
  const [color, setColor] = useColor("hex", getEffectiveColor(initialColor));
  const [model, setModel] = useState<ColorModel>("hex");
  const pendingColorRef = useRef(color);

  useEffect(() => {
    const effectiveColor = getEffectiveColor(initialColor);
    setColor(toColor("hex", effectiveColor));
  }, [initialColor, setColor, getEffectiveColor]);

  const handleModelChange = (newModel: ColorModel) => {
    setModel(newModel);
  };

  const ColorModeComponent = COLOR_MODE_COMPONENT_MAP[model];

  const handleSave = () => {
    onColorChange?.(pendingColorRef.current.hex);
    onAddCustomColor?.(pendingColorRef.current.hex);
    if (shouldRenderThemeColors) {
      setCurrentView(COLOR_PICKER_V2_VIEWS.THEME_COLORS);
    } else {
      onClose();
    }
  };

  return (
    <div className="color-picker--v2 flex w-full flex-col items-center">
      <ColorPickerComponent
        color={color}
        onChange={color => {
          setColor(color);
          pendingColorRef.current = color;
        }}
        alpha
        width={320}
        height={160}
        hideHEX
        hideRGB
        hideHSV
      />
      <div className="flex w-full items-center gap-2 px-4">
        <ColorModelSelector model={model} onChange={handleModelChange} />
        {ColorModeComponent && (
          <ColorModeComponent
            color={color}
            setColor={newColor => {
              setColor(newColor);
              pendingColorRef.current = newColor;
            }}
          />
        )}
      </div>
      <div className="mt-4 flex w-full justify-end px-4">
        <Button variant="circle" type="button" onClick={handleSave}>
          {t("save")}
        </Button>
      </div>
    </div>
  );
};
