interface ColorInputProps {
  value: number;
  onChange: (val: number) => void;
  min: number;
  max: number;
  step?: number;
}

export const ColorInput = ({
  value,
  onChange,
  min,
  max,
  step = 1,
}: ColorInputProps) => (
  <input
    type="number"
    value={value}
    onChange={event => {
      const number = Number(event.target.value);
      if (!Number.isNaN(number)) {
        onChange(number);
      }
    }}
    min={min}
    max={max}
    step={step}
    className="border-primary bg-primary text-dark group-hover:border-darkest focus:border-darkest group-focus-within:border-darkest w-1/4 rounded-lg border px-2 py-1 text-center [appearance:textfield] focus:!outline-none focus:!ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
  />
);
