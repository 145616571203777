import type { ReactNode } from "react";
import { useRef, useState } from "react";
import classNames from "classnames";
import I18n from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { UploadIcon } from "./UploadIcon";

export interface DropZoneProps {
  onFileDrop: (files: FileList | null) => void;
  accept?: string;
  dropTitle?: ReactNode;
  helperText?: ReactNode;
}

export const DropZone = ({
  onFileDrop,
  accept,
  dropTitle,
  helperText,
}: DropZoneProps) => {
  const [dragLevelCount, setDragLevelCount] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isDraggingInside = dragLevelCount > 0;

  const onClickHandler = (event: any) => {
    event.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        onChange={event => {
          event.preventDefault();
          onFileDrop(event.target.files);
        }}
        type="file"
        data-testid="upload_file"
        style={{ display: "none" }}
        accept={accept}
      />
      {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- We have <button> elements in the children */}
      <div
        role="button"
        tabIndex={0}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        onDragEnter={event => {
          event.preventDefault();
          event.stopPropagation();
          if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            setDragLevelCount(dragLevelCount + 1);
          }
        }}
        onDragLeave={event => {
          event.preventDefault();
          event.stopPropagation();
          setDragLevelCount(Math.max(0, dragLevelCount - 1));
        }}
        onDragOver={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onDrop={event => {
          event.preventDefault();
          event.stopPropagation();
          if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            onFileDrop(event.dataTransfer.files);
            event.dataTransfer.clearData();
          }
          setDragLevelCount(0);
        }}
        className={classNames(
          "upload-box w-full text-left",
          "focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          {
            "drag-inside": isDraggingInside,
          },
        )}
      >
        <div>
          <UploadIcon />
          {dropTitle && <div className="upload-box__title">{dropTitle}</div>}
          {helperText && <div className="upload-box__info">{helperText}</div>}
          <div className="flex w-full flex-row justify-center">
            <Button variant="circle" large>
              {I18n.t("choose_file")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
