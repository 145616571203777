import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { colord } from "colord";
import type { DebouncedFunc } from "lodash";
import { debounce } from "lodash";
import { toColor } from "react-color-palette";
import type { Color } from "react-color-palette";

interface HexInputProps {
  color: Color;
  setColor: (color: Color) => void;
}

export const HexInput = ({ color, setColor }: HexInputProps) => {
  const [hex, setHex] = useState(color.hex);
  const debouncedFnRef = useRef<DebouncedFunc<
    (hexValue: string) => void
  > | null>(null);

  const updateColor = useCallback(
    (hexValue: string) => {
      if (colord(hexValue).isValid()) {
        setColor(toColor("hex", hexValue));
      } else {
        console.warn("Invalid hex value", hexValue);
      }
    },
    [setColor],
  );

  useEffect(() => {
    debouncedFnRef.current = debounce(updateColor, 500);

    return () => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current.cancel();
      }
    };
  }, [updateColor]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setHex(value);
    if (debouncedFnRef.current) {
      debouncedFnRef.current(value);
    }
  }, []);

  useEffect(() => {
    setHex(color.hex);
  }, [color.hex]);

  return (
    <div className="flex w-4/5 flex-col items-center">
      <input
        type="text"
        value={hex}
        onChange={handleChange}
        className="border-primary bg-primary text-dark group-hover:border-darkest focus:border-darkest group-focus-within:border-darkest w-full appearance-none rounded-lg border px-2 py-1 text-center focus:!outline-none focus:!ring-0"
      />
    </div>
  );
};
