const UNIT_REGEX = /^(-?\d*\.?\d+)([a-zA-Z%]*)$/;

export const extractValueAndUnit = (
  input?: string,
): { value: number; unit: string } => {
  if (!input) return { value: 0, unit: "px" };
  const match = UNIT_REGEX.exec(input);
  return match
    ? { value: parseFloat(match[1]), unit: match[2] || "px" }
    : { value: 0, unit: "px" };
};

export const mergeValueAndUnit = (value: number, unit?: string): string => {
  if (!unit) return `${value}`;
  return `${value}${unit}`;
};
