import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import type { ColorPickerV2View } from ".";
import type { PopperProps } from "react-popper";

export interface PickerContextProps {
  themeColors?: Record<string, string>;
  initialColor?: string;
  onColorChange?: (color: string) => void;
  popperOptions?: PopperProps<any>["modifiers"];
  customColors?: Record<string, string>;
  currentView: ColorPickerV2View;
  setCurrentView: (view: ColorPickerV2View) => void;
  shouldRenderThemeColors?: boolean;
  onAddCustomColor?: (color: string) => void;
  cssVariables?: Record<string, string>;
}

const PickerContext = createContext<PickerContextProps | undefined>(undefined);

PickerContext.displayName = "PickerContext";

export interface PickerProviderProps extends PickerContextProps {
  children: ReactNode;
}

export const PickerProvider = ({
  initialColor = "#123123",
  onColorChange,
  popperOptions = [],
  themeColors,
  customColors,
  currentView,
  setCurrentView,
  shouldRenderThemeColors = false,
  onAddCustomColor,
  children,
  cssVariables,
}: PickerProviderProps) => {
  const contextValue = useMemo(
    () => ({
      initialColor,
      onColorChange,
      popperOptions,
      themeColors,
      customColors,
      currentView,
      setCurrentView,
      shouldRenderThemeColors,
      onAddCustomColor,
      cssVariables,
    }),
    [
      initialColor,
      onColorChange,
      popperOptions,
      themeColors,
      customColors,
      currentView,
      setCurrentView,
      shouldRenderThemeColors,
      onAddCustomColor,
      cssVariables,
    ],
  );

  return (
    <PickerContext.Provider value={contextValue}>
      {children}
    </PickerContext.Provider>
  );
};

export const usePickerContext = (): PickerContextProps => {
  const context = useContext(PickerContext);

  if (context === undefined) {
    throw new Error("usePickerContext must be used within a PickerProvider");
  }

  return context;
};
