import { COLOR_PICKER_V2_VIEWS } from ".";
import { t } from "@/i18n-js/instance";
import { IconButton } from "../uikit/HeaderV3/IconButton";
import { Typography } from "../uikit/Typography";
import { usePopoverPortalContext } from "./PopoverPortalContext";
import { usePickerContext } from "./pickerContext";

export const Header = () => {
  const { currentView, setCurrentView, shouldRenderThemeColors } =
    usePickerContext();
  const { onClose } = usePopoverPortalContext();
  const isCurrentViewPicker = currentView === COLOR_PICKER_V2_VIEWS.PICKER;
  const title =
    currentView === COLOR_PICKER_V2_VIEWS.THEME_COLORS
      ? t("color_picker_v2.color_picker")
      : t("color_picker_v2.custom_color");

  const handleClose = () => {
    onClose();
    if (shouldRenderThemeColors) {
      setCurrentView(COLOR_PICKER_V2_VIEWS.THEME_COLORS);
    }
  };

  return (
    <div className="border-primary flex w-full items-center justify-between border-b p-3">
      <div className="flex items-center gap-2">
        {isCurrentViewPicker && shouldRenderThemeColors && (
          <IconButton
            name="16-arrow-left"
            buttonSize={24}
            iconSize={16}
            ariaLabel={t("color_picker_v2.back")}
            onClick={() => setCurrentView(COLOR_PICKER_V2_VIEWS.THEME_COLORS)}
          />
        )}
        <Typography.BodyMd color="text-dark" weight="font-semibold">
          {title}
        </Typography.BodyMd>
      </div>
      <IconButton
        name="16-close"
        buttonSize={24}
        iconSize={16}
        ariaLabel={t("close")}
        onClick={handleClose}
      />
    </div>
  );
};
