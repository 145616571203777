import type { ReactNode } from "react";
import classNames from "classnames";
import { APPEARANCE } from "@/react/providers";
import { EmojiRendererWrapper } from "./EmojiRendererWrapper";

export interface EmojiRendererProps {
  emoji?: any;
  className?: string;
  wrapperClassName?: string;
  isWrapperElementClickable?: boolean;
  shouldUseLockIcon?: boolean;
  lockIcon?: ReactNode;
  emptyIcon?: ReactNode;
  appearance: "light" | "dark";
  customEmojiUrl?: string | null;
  customEmojiDarkUrl?: string | null;
  emojiClassName?: string;
  dataTestId?: string;
}

export const EmojiRenderer = ({
  emoji,
  className = "",
  wrapperClassName = "",
  isWrapperElementClickable = false,
  shouldUseLockIcon = false,
  lockIcon,
  emptyIcon,
  appearance,
  customEmojiUrl,
  customEmojiDarkUrl,
  emojiClassName = "",
  dataTestId = "",
}: EmojiRendererProps) => {
  const generateSrcUrl = () => {
    if (customEmojiDarkUrl && appearance === APPEARANCE.DARK) {
      return customEmojiDarkUrl;
    }
    if (customEmojiUrl) {
      return customEmojiUrl;
    }
    return undefined;
  };

  if (customEmojiUrl) {
    return (
      <EmojiRendererWrapper
        wrapperClassName={wrapperClassName}
        isWrapperElementClickable={isWrapperElementClickable}
      >
        {(shouldUseLockIcon && lockIcon) || (
          <img
            loading="lazy"
            src={generateSrcUrl()}
            alt=""
            aria-hidden
            className={classNames("max-h-6 max-w-full", className)}
          />
        )}
      </EmojiRendererWrapper>
    );
  }

  return (
    <EmojiRendererWrapper
      wrapperClassName={wrapperClassName}
      isWrapperElementClickable={isWrapperElementClickable}
    >
      <span
        aria-label={emoji}
        aria-hidden
        className={classNames(
          {
            "text-center !text-base": emoji === "#",
          },
          className,
          emojiClassName,
        )}
        data-testid={dataTestId}
      >
        {(shouldUseLockIcon && lockIcon) || emoji || emptyIcon}
      </span>
    </EmojiRendererWrapper>
  );
};
